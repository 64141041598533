
@font-face {
  font-family: 'FuturaLT-bold'; /*a name to be used later*/
  src: url('/public/fonts/FuturaLT/FuturaLT-Bold.ttf'); /*URL to font*/
}
@font-face {
  font-family: 'FuturaLT-book'; /*a name to be used later*/
  src: url('/public/fonts/FuturaLT/FuturaLT-Book.ttf'); /*URL to font*/
}
@font-face {
  font-family: 'FuturaLT-extra-bold'; /*a name to be used later*/
  src: url('/public/fonts/FuturaLT/FuturaLT-ExtraBold.ttf'); /*URL to font*/
}

:root {
  --clr-border: #2388e9;
  --AnimationLength: 0px;
}
body {
  background: rgb(2,39,88);
  background: linear-gradient(90deg, rgba(3,80,145,1) 19%,rgba(2,39,88,1)  77%);
  overflow-x: hidden;
  margin: 0;
  padding: 0px;
  font-weight: bolder;
  font-family: "FuturaLT-bold";
}

.p{
  font-family: "FuturaLT-book";
}
h1{
  font-family: "FuturaLT-extra-bold";
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  animation-name: fadeIn;
  animation-duration: 3000ms;
}
.animate-content{
  transition: all 0.3s ease-in-out;
}

.hide-scrollbar{
  -ms-overflow-style: none; /* Edge, Internet Explorer */
  scrollbar-width: none; /* Firefox */
  overflow-y: scroll;
}

.bg-footy {
  /* #035091
  #022758 */
  background: rgb(2,39,88);
  background: linear-gradient(90deg, rgba(2,39,88,1) 19%, rgba(3,80,145,1) 77%);

}

.hide-scrollbar::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}