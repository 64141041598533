@import url('https://fonts.googleapis.com/css2?family=Baloo+Tammudu+2:wght@400;500;600;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;


body{
    margin: 0;
    padding: 0;
    width: 100%;
    /* font-family: 'Baloo Tammudu 2', cursive; */
}

